body {
  font-size: 18px;
  --font-name: 'Montserrat', sans-serif;
  font-family: var(--font-name);
  margin: 20px;
  /*background-image: url("SaferStadia_data.jpg");*/
  /*background-image: url("SaferStadia_data.jpg");*/
  background-image: url("SaferStadia_SEATING.jpg");
  /*background-image: linear-gradient(var(--light-blue), var(--dark-blue));*/
  /*background-image: linear-gradient(var(--dark-blue), var(--light-blue));*/
  background-color: var(--dark-blue);

  color: var(--white);
}

/* this does not work yet */
hr {
  background-color: var(--light-blue);
}

/*.backgroundNonImage {*/
.heroImage {
  position: relative;
  z-index: 1;
}

.heroImage {
  /*
  z-index: -1;
  background-image: url("SaferStadia_SEATING.jpg");
  background-color: var(--dark-blue);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  */

    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
 /*   background-image: url("SaferStadia_SEATING.jpg") center center;*/


    /*
  position: absolute;
  opacity: 0.9;
  */

  /*
  background-blend-mode: color;

  filter: blur(8px);
  -webkit-filter: blur(8px);
  */
}

.headPage {
  font-family: var(--font-name);
  font-weight: 500;
}

.headPage {
  font-size: 30px;
  text-align: center;
}

.headPage.red {
  color: var(--header-colour);
}

.headDesc {
  font-size: 24px;
  font-weight: 500;
}

.headDesc {
  margin-top: 36px;
  font-family: var(--font-name);
  text-align: center;
}


ol, ul {
  padding-left: 30px;
}

.status {
  margin-bottom: 10px;
}

/* beware of adding other images!! */
.logo-image {
  max-width: 30%;
  max-height: 30%;
}

.logoDiv {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
}

.legendTitle {
  color: var(--white);
}

/* leftover from sample starter file?
.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}
*/

.kbd-navigation .square:focus {
  background: #ddd;
}

input {
  border-radius: 4px;
  width: 50px;
  text-align: right;
  float: right;
  /*color: #165788;*/
  color: var(--dark-blue);
}

input:disabled {
  background-color: var(--disabled-input-color);
}

table.covidTable {
  padding: 5px;
  margin: 5px;
  margin-left:auto; 
  margin-right:auto;
}

table.legend {
  padding: 15px;
  width: 440px;
  display: block;
}

table.legend td {
  color: var(--dark-blue);
}

tr {
  padding: 25px;
  margin: 25px;
}



:root {

  --white: #ffffff;

  /* branded colours */
  --dark-blue: #004B78;
  --light-blue: #00A0D2;
  --dark-green: #008064;
  --light-green: #3CAC28;

  --header-colour: var(--light-blue);

  /* disabled input color */
  --disabled-input-color: #cfcfcf;

  /* seatmap colours */
  --grey: #dcdcdc;
  --red: #f98e8a;
  --orange: #f9b88a;
  --yellow: #ffffbf;
  --green: #ccffc2;
  --blue: #a0caeb;
}

.redBackground {
  background-color: var(--red);
}

.orangeBackground {
  background-color: var(--orange);
}

.yellowBackground {
  background-color: var(--yellow);
}

.greenBackground {
  background-color: var(--green);
}

.blueBackground {
  background-color: var(--blue);
}

.greyBackground {
  background-color: var(--grey);
}

.seatTable {
  align-items: center;
}

.seatCell {
  width: 30px;
  text-align: right;
}

.seatColumn {
  padding: 1px;
}

.seatCell.modeledSeat {
  text-align: center;
  font-weight: bold;
  background-color: var(--dark-blue);
  border-color: var(--white);
  color: var(--white);
}

.seatCell.grey {
  background-color: var(--grey);
}

.seatCell.red {
  background-color: var(--red);
}

.seatCell.orange {
  background-color: var(--orange);
}

.seatCell.yellow {
  background-color: var(--yellow);
}

.seatCell.green {
  background-color: var(--green);
}

.seatCell.blue {
  background-color: var(--blue);
}

.totalEventAnalysisTable {
  font-size: 24px;
  float: center;
}
 
input.totalEventAnalysisTable {
  width: 80px;
}

.totalTravelAnalysisTable {
  float: center;
}

a {
  color: var(--light-blue);
}

/* testing with % */
.valuePadding {
  border: 1px inset #ccc;
}

.valuePadding input {
  border: none;
  padding:0px;
  outline: none;
}

tr.spaceUnder {
  border-spacing: 5em;
}
